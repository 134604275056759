import Cookies from 'universal-cookie'

/*
 * Google Tag Manager (Consent Mode v2)
 * See https://developers.google.com/tag-platform/security/guides/consent?hl=de&consentmode=basic#default-consent
 */

function gtag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

function setGtagConsent(consentValue, eventType) {
  const value = consentValue || 'denied'

  gtag('consent', eventType, {
    ad_user_data: value,
    ad_personalization: value,
    ad_storage: value,
    analytics_storage: value,
  })
}

function insertGtagScript() {
  const gtmId = 'GTM-N4GQSLF'

  const script = document.createElement('script')
  document.head.appendChild(script)
  script.innerHTML = `
    <!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
    <!-- End Google Tag Manager -->
    `

  const noscript = document.createElement('noscript')
  document.body.prepend(noscript)
  noscript.innerHTML = `
    <!-- Google Tag Manager (noscript) -->
    <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
    <!-- End Google Tag Manager (noscript) -->
    `
}

/*
 * Salesviewer
 */

function insertSalesviewerScript() {
  const script = document.createElement('script')
  document.head.appendChild(script)
  script.innerHTML = `
    !(function (s, a, l, e, sv, i, ew, er) {try {(a =s[a] || s[l] || function () {throw "no_xhr";}),(sv = i = "https://salesviewer.org"),(ew = function(x){(s = new Image()),(s.src = "https://salesviewer.org/tle.gif?sva=H6t3B6W6T7n1&u="+encodeURIComponent(window.location)+"&e=" + encodeURIComponent(x))}),(l = s.SV_XHR = function (d) {return ((er = new a()),(er.onerror = function () {if (sv != i) return ew("load_err");(sv = "https://www.salesviewer.com/t"), setTimeout(l.bind(null, d), 0);}),(er.onload = function () {(s.execScript || s.eval).call(er, er.responseText);}),er.open("POST", sv, !0),(er.withCredentials = true),er.send(d),er);}),l("h_json=" + 1 * ("JSON" in s && void 0 !== JSON.parse) + "&h_wc=1&h_event=" + 1 * ("addEventListener" in s) + "&sva=" + e);} catch (x) {ew(x)}})(window, "XDomainRequest", "XMLHttpRequest", "H6t3B6W6T7n1");
    `

  const noscript = document.createElement('noscript')
  document.body.prepend(noscript)
  noscript.innerHTML = `
    <img
    src="https://salesviewer.org/H6t3B6W6T7n1.gif"
    style="visibility:hidden;"
    />
    `
}

/*
 * Mouseflow
 */

function insertMouseflowScript() {
  const script = document.createElement('script')
  document.head.appendChild(script)
  script.innerHTML = `
    window._mfq = window._mfq || [];
    (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/94d4fd33-954d-48bf-aa22-85d586800f27.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
    })();
    `
}

/*
 * Initialize Analytics
 * (Called once by gatsby-browser.js on initial page load)
 */

export function initAnalytics() {
  const cookies = new Cookies()
  const consentCookieValue = cookies.get('cookie_consent')

  switch (consentCookieValue) {
    case 'granted':
      setGtagConsent('granted', 'default')
      insertGtagScript()

      insertSalesviewerScript()
      insertMouseflowScript()
      break
    case 'denied':
    default:
      setGtagConsent('denied', 'default')
      insertGtagScript()
      break
  }
}

/*
 * Update Analytics
 * (Called when the cookie banner has been answered)
 */

export function updateAnalytics(consentValue) {
  const cookies = new Cookies()
  const monthInSeconds = 60 * 60 * 24 * 30

  cookies.set('cookie_consent', consentValue, { maxAge: monthInSeconds * 3 })

  switch (consentValue) {
    case 'granted':
      setGtagConsent('granted', 'update')

      insertSalesviewerScript()
      insertMouseflowScript()
      break
    case 'denied':
    default:
      // do nothing
      break
  }
}

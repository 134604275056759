/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css'
import './src/assets/_global.scss'
import { initAnalytics } from './src/lib/analytics'
import { stripSlashes } from './src/lib/helpers'

export const onClientEntry = () => {
  const pathname = stripSlashes(window.location.pathname)

  if (process.env.NODE_ENV === 'production' && pathname !== 'preview') {
    initAnalytics()
  }
}

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production`) {
    // This is a for Google Analytics
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: `gatsby-route-change` })
    }, 50)
  }
}
